<template>
  <div>
    <div class="row">
      <div class="col-md-3 text-right">
        Check off all the attributes below that describe you at age:
      </div>
      <div class="col-md-3">
        <InputFormContainer
          type="input"
          meta-key="attributes-before-age"
          @save="saveChanges"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-4" v-for="[key, label] in attributes1" :key="key">
        <label>
          <InputFormContainer
            type="checkbox"
            :meta-key="`attributes-before-${key}`"
            @save="saveChanges"
            :placeholder="label"
          />
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-10">
        <p>Add your own attributes:</p>
        <InputFormContainer
          meta-key="attributes-before-own"
          @save="saveChanges"
          :height="150"
        />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-3 text-right">
        Check off all the attributes below that describe you at age:
      </div>
      <div class="col-md-3">
        <InputFormContainer
          type="input"
          meta-key="attributes-now-age"
          @save="saveChanges"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-4" v-for="[key, label] in attributes1" :key="key">
        <label>
          <InputFormContainer
            type="checkbox"
            :meta-key="`attributes-now-${key}`"
            @save="saveChanges"
            :placeholder="label"
          />
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-10">
        <p>Add your own attributes:</p>
        <InputFormContainer
          meta-key="attributes-now-own"
          @save="saveChanges"
          :height="150"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputFormContainer from "../InputFormContainer.vue";
export default {
  components: {
    InputFormContainer,
  },
  data() {
    return {
      content: "",
    };
  },
  computed: {
    attributes1() {
      const attrs = {
        patient: "Patient",
        guarded: "Guarded",
        resilient: "Resilient",
        shy: "Shy",
        intelligent: "Intelligent",
        stubborn: "Stubborn",
        sociallyaware: "Socially Aware",
        deliberate: "Deliberate",
        manipulative: "Manipulative",
        kind: "Kind",
        gentle: "Gentle",
        reckless: "Reckless",
        strong: "Strong",
        caring: "Caring",
        assertive: "Assertive",
        funny: "Funny",
        punctual: "Punctual",
        friendly: "Friendly",
        humane: "Humane",
        hardworking: "Hard-Working",
        reliable: "Reliable",
        honest: "Honest",
        practical: "Practical",
        responsible: "Responsible",
        loyal: "Loyal",
        mature: "Mature",
        creative: "Creative",
        impulsive: "Impulsive",
        consistent: "Consistent",
        appreciative: "Appreciative",
        capable: "Capable",
        quick: "Quick",
        nervous: "Nervous",
        sensitive: "Sensitive",
        perceptive: "Perceptive",
        thoughtful: "Thoughtful",
        fit: "Fit",
        pessimistic: "Pessimistic",
        trustworthy: "Trustworthy",
        showsinitiative: "Shows Initiative",
        motivated: "Motivated",
        versatile: "Versatile",
        educated: "Educated",
        willing: "Willing",
        experienced: "Experienced",
        efficient: "Efficient",
        rowdy: "Rowdy",
        openminded: "Open-Minded",
        logical: "Logical",
        serious: "Serious",
        supportive: "Supportive",
        resourceful: "Resourceful",
        realistic: "Realistic",
        impatient: "Impatient",
      };
      return Object.entries(attrs);
    },
  },
  methods: {
    saveChanges({ metaKey, content }) {
      switch (metaKey) {
        default:
          this.$store.dispatch("updateUserMeta", [
            metaKey,
            content,
            ({ message }) => {
              this.$toast.open({
                message,
                position: "bottom-left",
                type: "success",
              });
            },
          ]);
          break;
      }
    },
  },
};
</script>

<style scoped>
div .input-form {
  height: 500px;
}
</style>